@import "Styles";

#BandPage {
  margin: auto;
  background: $theme_bg;

  * {
    color: $theme_text;
  }

  a {
    text-decoration: none;
    color: $theme_primary;

    &:hover {
      color: $theme_secondary;
    }
  }

  .blurbContainer {
    display: flex;
    padding-bottom: 8px;

    @media only screen and (max-width: 1000px) {
      flex-direction: column-reverse;

      &.alt {
        flex-direction: column;
      }

      .youtubeVideos {
        flex-direction: column;
      }
    }

    &.alt {
      background: $theme_window;
    }

    .playlistWrapper {
      margin: 24px;
    }

    .youtubeVideos {
      padding: 16px;

      > * {
        margin: 8px;
      }
    }

    > * {
      display: flex;
    }

    > .CaptionedImage {
      margin-top: -32px;
    }

    > :first-child {
      padding-left: 5vw;
    }

    > :last-child {
      padding-right: 5vw;
    }

    > span {
      flex: 1;
      padding: 16px;
      flex-direction: column;
      padding-top: 8px;
    }
  }
}
