$theme_bg: #0b0c10;
$theme_window: #1f2833;
$theme_text: #c5c6c7;
$theme_primary: #66fcf1;
$theme_secondary: #45a29e;

:export {
  background: $theme_bg;
  window: $theme_window;
  text: $theme_text;
  primary: $theme_primary;
  secondary: $theme_secondary;
}
