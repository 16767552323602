@import "./theme.scss";

body,
html,
#root {
  margin: 0;
  padding: 0;
}

* :not(code) {
  font-family: Roboto, Arial, Helvetica, sans-serif;
}
