@import "Styles";

.CaptionedImage {
  display: flex;
  align-items: center;
  flex-direction: column;

  > div {
    margin: 16px;
    min-width: 32px;
    min-height: 32px;
    background-size: cover;
    overflow: hidden;
    border-radius: 12px;
    border: 1px solid $theme_secondary;
  }

  > span {
    margin: 0 16px 16px;
    font-style: italic;
    font-size: 12px;
  }
}
