#SplashPage {
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;

  .logo {
    flex: 1;
    max-width: 512px;
    filter: drop-shadow(8px 16px 10px gray);
  }
}
